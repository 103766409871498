import { ref, computed, watch } from '@vue/composition-api';
import moment from 'moment';
import CalendarMeetingType from '@/views/apps/calendar/constants/CalendarMeetingType';
import CalendarOptions from '@/views/apps/calendar/constants/CalendarOptions';

function defaultMeetingTitle($t, event) {

  const isSameDay = event.start && event.end && new Date(event.start).getDate() === new Date(event.end).getDate();

  const translationObject = {
    startDate: moment(event.start).format(event.extendedProps.allDay ? 'YYYY-MM-DD' : 'YYYY-MM-DD h:mma'),
    endDate: isSameDay ? moment(event.end).format('h:mma') : moment(event.end).format('YYYY-MM-DD h:mma'),
  };

  if (event.extendedProps.allDay) {
    return $t('calendar.meeting-form.title.default.all-day', translationObject);
  }
  if (!event.end) {
    return $t('calendar.meeting-form.title.default.no-end-date', translationObject);
  }

  return $t('calendar.meeting-form.title.default.start-and-end-date', translationObject);
}

export default function useCalendarEventForm(props, clearForm, emit, t) {
  // ------------------------------------------------
  // eventLocal
  // ------------------------------------------------
  const eventLocal = ref(null);
  const resetEventLocal = () => {
    eventLocal.value = JSON.parse(JSON.stringify(props.event.value));
  };
  resetEventLocal();

  watch(props.event, () => {
    resetEventLocal();
  });

  const computedStartDate = computed(() => eventLocal.value.start);
  watch(computedStartDate, (value) => {
    if (!eventLocal.value.end || eventLocal.value.end < eventLocal.value.start) {
      const date = new Date(value);
      date.setMinutes(date.getMinutes() + 30);

      eventLocal.value.end = date.toISOString();
    }
  });

  const defaultTitle = computed(() => defaultMeetingTitle(t, eventLocal.value));

  // ------------------------------------------------
  // visible
  // * Clear form if the form is closed
  // ------------------------------------------------
  watch(props.visible, (val) => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value();
      }, 350);
    }
  });
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
  const calendarOptions = computed(() => CalendarOptions
    .filter(({ calendar }) => calendar === CalendarMeetingType.Meeting));

  const onSubmit = () => {
    const eventData = JSON.parse(JSON.stringify(eventLocal));

    if (!eventData.value.title) {
      eventData.value.title = defaultTitle.value;
    }

    if (!eventData.value.end) {
      const startDate = new Date(eventData.value.start);
      startDate.setMinutes(startDate.getMinutes() + 30);

      eventData.value.end = startDate.toISOString();
    }

/*     if (eventData.value.extendedProps.allDay) {
      eventData.value.end = null;
    } */

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.event.value.id) emit('update-event', { newEvent: eventData.value, oldEvent: props.event.value });
    else emit('add-event', eventData.value);

    // Close sidebar
    emit('update:visible', false);
  };

  const onRemoveEvent = () => {
    const eventData = JSON.parse(JSON.stringify(eventLocal));

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.event.value.id) {
      emit('remove-event', eventData.value);
    }

    // Close sidebar
    emit('update:visible', false);
  };

  return {
    eventLocal,
    defaultTitle,
    resetEventLocal,
    calendarOptions,

    onSubmit,
    onRemoveEvent,
  };
}
