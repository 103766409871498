<template>
  <!-- Body -->
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <!-- Form -->
    <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
      <!-- Title -->
      <validation-provider #default="validationContext" :name="$t('calendar.meeting-form.title.name')">
        <b-form-group
          :label="$t('calendar.meeting-form.title.label')"
          label-for="event-title"
          :description="!eventLocal.title ? $t('calendar.meeting-form.title.default-message', [defaultTitle]) : ''"
        >
          <b-form-input
            id="event-title"
            v-model="eventLocal.title"
            autofocus
            :state="getValidationState(validationContext)"
            trim
            :placeholder="$t('calendar.meeting-form.title.placeholder')"
          />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- Meeting type -->
      <!--validation-provider
        #default="validationContext"
        :name="$t('calendar.meeting-form.calendar.name')"
        :rules="calendarOptions.length > 1 ? 'required' : ''"
      >
        <b-form-group
          :label="`${$t('backoffice.types.type')}${calendarOptions.length > 1 ? ' *' : ''}`"
          label-for="calendar"
          :state="getValidationState(validationContext)"
        >
          <div v-if="calendarOptions.length === 1">
            <div
              class="rounded-circle d-inline-block mr-50"
              :class="calendarOptions[0].className"
              style="height:10px;width:10px"
            />
            <span>{{ $t(calendarOptions[0].label) }} </span>
          </div>
          <v-select
            v-else
            v-model="eventLocal.extendedProps.calendar"
            dir="ltr"
            :options="calendarOptions"
            label="label"
            :reduce="(calendar) => calendar.calendar"
            input-id="calendar"
          >
            <template #option="{ className, label }">
              <div class="rounded-circle d-inline-block mr-50" :class="className" style="height:10px;width:10px" />
              <span> {{ $t(label) }}</span>
            </template>

            <template #selected-option="{ className, label }">
              <div class="rounded-circle d-inline-block mr-50" :class="className" style="height:10px;width:10px" />
              <span>{{ $t(label) }} </span>
            </template>
          </v-select>

          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider-->

      <b-row>
        <b-col cols="12" lg="6">
          <!-- Modality: online or on-site -->
          <b-form-radio-group
            v-model="selectedOptionModality"
            :options="options"
            class="mb-1"
            @input="getSelectedOption"
          />
        </b-col>

        <b-col cols="12" lg="6">
          <!-- All Day -->
          <b-form-group>
            <b-form-checkbox
              v-model="eventLocal.extendedProps.allDay"
              name="check-button"
              switch
              inline
            >
              {{ $t('calendar.meeting-form.all-day') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Dates no all day -->
      <b-row>
        <b-col v-if="!eventLocal.extendedProps.allDay" cols="12" lg="6">
          <!-- Start Date -->
          <validation-provider
            #default="validationContext"
            vid="start-date"
            :name="$t('calendar.meeting-form.start-date.name')"
            rules="required"
          >
            <b-form-group
              :label="`${$t('calendar.meeting-form.start-date.label')}* (${$t('events.event-details.local-time')})`"
              label-for="start-date"
              :state="getValidationState(validationContext)"
            >
              <VueCtkDateTimePicker
                id="startDate"
                key="1"
                v-model="eventLocal.start"
                :locale="locale"
                format="YYYY-MM-DD HH:mm"
                input-size="lg"
                :label="$t('datepicker.select-label')"
                color="var(--primary)"
                no-button-now
                button-color="var(--primary)"
                :enabled-dates="dates"
                :minute-interval="minuteInterval"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.failedRules.required }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col v-if="!eventLocal.extendedProps.allDay" cols="12" lg="6">
          <!-- End Date -->
          <validation-provider
            #default="validationContext"
            :name="$t('calendar.meeting-form.end-date.name')"
          >
            <b-form-group
              :label="`${$t('calendar.meeting-form.end-date.label')} (${$t('events.event-details.local-time')})`"
              label-for="end-date"
            >
              <VueCtkDateTimePicker
                id="endDate"
                v-model="eventLocal.end"
                :locale="locale"
                format="YYYY-MM-DD HH:mm"
                :label="$t('datepicker.select-label')"
                color="var(--primary)"
                no-button-now
                input-size="lg"
                button-color="var(--primary)"
                :min-date="eventLocal.start"
                :enabled-dates="dates"
                :minute-interval="minuteInterval"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Dates if All day -->
        <b-col v-if="eventLocal.extendedProps.allDay" cols="12" lg="6">
          <!-- Start Date -->
          <validation-provider
            #default="validationContext"
            vid="start-date-all"
            :name="$t('calendar.meeting-form.start-date.name')"
            rules="required"
          >
            <b-form-group
              :label="`${$t('calendar.meeting-form.start-date.label')}*  (${$t('events.event-details.local-time')})`"
              label-for="start-date-all"
              :state="getValidationState(validationContext)"
            >
              <VueCtkDateTimePicker
                id="start-date-all"
                key="0"
                v-model="eventLocal.start"
                :locale="locale"
                formatted="ll"
                :label="$t('datepicker.select-label')"
                color="var(--primary)"
                no-button-now
                only-date
                input-size="lg"
                button-color="var(--primary)"
                :enabled-dates="dates"
                :minute-interval="minuteInterval"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.failedRules.required }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col v-if="eventLocal.extendedProps.allDay" cols="12" lg="6">
          <!-- End Date -->
          <validation-provider
            #default="validationContext"
            vid="end-date-all"
            :name="$t('calendar.meeting-form.end-date.name')"
          >
            <b-form-group
              :label="`${$t('calendar.meeting-form.end-date.label')} (${$t('events.event-details.local-time')})`"
              label-for="end-date-all"
            >
              <VueCtkDateTimePicker
                id="end-date-all"
                v-model="eventLocal.end"
                :locale="locale"
                :label="$t('datepicker.select-label')"
                color="var(--primary)"
                no-button-now
                format="YYYY-MM-DD"
                formatted="ll"
                only-date
                input-size="lg"
                button-color="var(--primary)"
                :min-date="eventLocal.start"
                :enabled-dates="dates"
                :minute-interval="minuteInterval"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- TimeZone -->
      <b-row>
        <b-col cols="12" lg="6">
          <b-form-group>
            <label for="meeting-time-zone" class="font-weight-bold">
               {{ $t('calendar.meeting-form.meeting-time-zone') }}
            </label>
            <v-select
              id="meeting-time-zone"
              v-model="selectedOptionTimezone"
              :searchable="false"
              :placeholder="$t('form.select-placeholder')"
              :options="timeZoneOptions"
              :clearable="false"
              @input="getSelectedTimezone"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="6">
          <b-form-group>
            <label for="local-time-zone" class="font-weight-bold">
               {{ $t('calendar.meeting-form.local-time-zone') }}
            </label>
            <div id="local-time-zone">
              {{ localTimezone }}
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Rules Message -->
      <div v-if="0 && availabilityRules.length > 0" class="info-message">
        <span>{{ $t('calendar.meeting-form.availability-rules') }}:</span><br>
        <span v-if="currentSpaceKey === 'f97e5c40-f5bf-11ed-9caa-cf40ae67e751'">
          - Reuniones de 30 minutos
        </span>
        <span v-for="(row, index) in availabilityRules" :key="row.key">
          - {{ getAvailabilityRulesMessage(row) }}<br>
        </span>
      </div>

      <!-- Guests -->
      <validation-provider 
        #default="validationContext" 
        :name="$t('calendar.meeting-form.guest-list.name')"
      >
        <b-form-group
          :label="$t('calendar.meeting-form.guest-list.label')"
          label-for="add-guests"
          :state="getValidationState(validationContext)"
        >
          <members-select
            v-model="eventLocal.extendedProps.guests"
            dir="ltr"
            multiple
            :close-on-select="false"
            :fetch-data="fetchData"
            input-id="add-guests"
            :placeholder="$t('calendar.meeting-form.guests.placeholder')"
          >
            <template #option="option">
              <b-avatar
                size="sm"
                :src="option.avatarURL"
              />
              <span class="ml-50 align-middle">{{ option.name }} {{ option.surname }}</span>
            </template>

            <template #selected-option="option">
              <b-avatar
                size="sm"
                class="border border-white"
                :src="option.avatarURL"
              />
              <span class="ml-50 align-middle">{{ option.name }} {{ option.surname }}</span>
            </template>
          </members-select>
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- Location -->
      <validation-provider
        v-if="selectedOptionModality === 'on-site'"
        v-slot="validationContext"
        vid="location"
        :name="$t('calendar.meeting-form.location.name')"
      >
        <b-form-group :label="`${$t('calendar.meeting-form.location.label')}`" label-for="event-location">
          <places-map-input
            v-model="eventLocal.extendedProps.location"
            :placeholder="$t('calendar.meeting-form.location.placeholder')"
          />

          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- Textarea -->
      <validation-provider
        #default="validationContext"
        :name="$t('calendar.meeting-form.description.name')"
      >
        <b-form-group :label="$t('calendar.meeting-form.description.label')" label-for="event-description">
          <quill-editor
            id="event-description"
            v-model="eventLocal.extendedProps.description"
            rows="5"
            class="quillE"
            :placeholder="$t('calendar.meeting-form.description.placeholder')"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-2"
          type="submit"
        >
          {{ eventLocal.id ? $t('action.update') : $t('action.add') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          class="mr-2"
          variant="outline-secondary"
        >
          {{ $t('action.reset') }}
        </b-button>
        <b-button
          v-if="eventLocal.id"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-danger"
          size="sm"
          @click="onRemoveEvent"
        >
          {{ $t('calendar.cancel-event') }}
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  required, email, url, greaterThan,
} from '@validations';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import formValidation from '@core/comp-functions/forms/form-validation';
import { ref, toRefs } from '@vue/composition-api';
import MembersSelect from '@/views/apps/member/components/MembersSelect.vue';
import PlacesMapInput from '@core/components/places-map-input/PlacesMapInput.vue';
import PlainEditor from '@core/components/editor/PlainEditor.vue';
import Service from '@/config/service-identifiers';
import { quillEditor } from 'vue-quill-editor';
import useCalendarEventForm from './useCalendarEventForm';
import { log } from 'video.js';

// CalendarEventForm.vue former CalendarEventHandler.vue in vuexy
export default {
  name: 'CalendarEventForm',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    MembersSelect,
    PlacesMapInput,
    PlainEditor,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      greaterThan,
      startDate: null,
      endDate: null,
      rulesMsg: '',
      dates: [],
      finalHours: null,
      minuteInterval: '1',
      selectedOptionModality: '',
      selectedOptionTimezone: null,
      timeZone: '',
      timeZoneOptionsFull: {},
      timeZoneOptions: [],
    };
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get
     * undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from
     * `useCalendarEventForm` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to
     * `useFormValidation` and in return we will get `clearForm` function which shall be original value of
     * `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventForm` for first time it will be null but right after it we are getting
     * correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is
     * also update in `useCalendarEventForm` composition function and it is getting correct value in second time and
     * can work w/o any issues.
    */
    /* const date = new Date(new Date().getFullYear(), 0, 1);

    const dates = [];

    let cont = 0;

    while (cont !== 365) {
      dates.push(new Date(date).toISOString().split('T')[0]);
      date.setDate(date.getDate() + 1);
      cont++;
    } */

    const clearFormData = ref(null);

    const { t } = useI18nUtils();

    const {
      eventLocal,
      defaultTitle,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      onRemoveEvent,
    } = useCalendarEventForm(toRefs(props), clearFormData, emit, t);

    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation(
      resetEventLocal,
      props.clearEventData,
    );

    clearFormData.value = clearForm;
    eventLocal.value.start = props.event.start;
    eventLocal.value.end = props.event.end;

    return {
      // Add New Event
      eventLocal,
      defaultTitle,
      calendarOptions,
      onSubmit,
      onRemoveEvent,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    };
  },
  computed: {
    options() {
      return [
        { value: 'online', text: this.$t('calendar.meeting-form.location.options.online') },
        { value: 'on-site', text: this.$t('calendar.meeting-form.location.options.on-site') },
      ];
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    availabilityRules() {
      return this.$store.getters.availabilityRules.unpaginated;
    },
    eventGuests() {
      const { guests } = this.eventLocal.extendedProps;
      const noDuplicateGuests = guests.filter((guest, index, self) => index === self.findIndex((secondGuest) => (secondGuest.key === guest.key)));
      return noDuplicateGuests.filter((guest) => guest.key !== this.eventLocal.extendedProps.ownerKey);
    },
    localTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  },
  async created() {
    // Get all timezones
    await this.getTimezones();

    const currentSpaceKey = this.$store.getters.currentCollective?.key;
    const mainSpaceKey = this.$store.getters.mainCollective?.key;

    if (this.eventLocal.extendedProps.event) {
      // editing the meeeting
      this.eventLocal.extendedProps.guests = this.eventGuests;
      // Apply the meeting timezone:
      this.timeZone = Object.values(this.timeZoneOptions).filter((i) => i === this.eventLocal?.timezone)[0];
      this.selectedOptionTimezone = this.eventLocal.extendedProps.event.timezone;
    }else{
      // creating a new meeting
      if(currentSpaceKey === 'e1326b70-dc13-11ec-9877-3b619755c76d' || mainSpaceKey === 'e1326b70-dc13-11ec-9877-3b619755c76d'){
        // If it is CCMA start in on-site
        this.eventLocal.extendedProps.modality = 'on-site';
      }
      // Apply the community timezone:
      this.timeZone = Object.values(this.timeZoneOptions).filter((i) => i === this.$store.getters.currentCollective?.timezone)[0];
      this.selectedOptionTimezone = this.$store.getters.currentCollective?.timezone;
      this.eventLocal.extendedProps.timezone = this.timeZone;
    }
    // rueda-de-negocios-bureau:
    if(currentSpaceKey === 'f97e5c40-f5bf-11ed-9caa-cf40ae67e751'){
      this.minuteInterval = '30';
    }

    await this.fetchAvailabilityRules();
    this.checkAvailability();

    this.selectedOptionModality = this.eventLocal.extendedProps.modality || 'online';
  },
  methods: {
    isExistingEvent() {
      this.eventLocal.end = new Date(this.eventLocal.extendedProps.event.endDate);
      this.eventLocal.start = new Date(this.eventLocal.extendedProps.event.startDate);
    },
    async getTimezones() {
      const response = await this.$store.dispatch('getTimezones');
      this.timeZoneOptionsFull = response;
      this.timeZoneOptions = Object.values(response);
    },
    async fetchData(page, searchString = '') {
      if (!searchString) {
        return this.$store.dispatch('getItemsNode', {
          itemType: 'communityMembers',
          page,
          perPage: 16,
        });
      }
      const response = await this.$store.$service[Service.BackendClient].get('communityMembers', {
        params: {
          communityKey: this.$store.getters.currentCollective.key,
          searchString,
          page,
          perPage: 16,
        },
      });

      return response.data;
    },
    getDatesInRange(startDate, endDate) {
      const date = new Date(startDate);
      const dates = [];

      while (date <= endDate) {
        dates.push(this.getDate(date));
        date.setDate(date.getDate() + 1);
      }

      return dates;
    },
    getDate(date) {
      date = new Date(date);
      return `${date
        .toISOString()
        .split('T')[0]
        .replaceAll('/', '-')}`;
    },
    getDateAndTimeStr(date) {
      date = new Date(date);
      //transform to timezone:

      const dateArray = date.toISOString().replaceAll('/', '-').split('T');
      const timeArray = dateArray[1].split(':');
      return `${timeArray[0] + ':' + timeArray[1] + ' on ' + dateArray[0]}`;
    },
    async fetchAvailabilityRules() {
      await this.$store.dispatch('getItems', {
        itemType: 'availabilityRules',
        page: 1,
      });
    },
    getSelectedOption(option) {
      if (option === 'online') {
        this.eventLocal.extendedProps.location = null;
        this.eventLocal.extendedProps.modality = 'online';
      }
      if (option === 'on-site') this.eventLocal.extendedProps.modality = 'on-site';
    },
    eventTimezone(){
      return this.eventLocal?.extendedProps?.event?.timezone;
    },
    getSelectedTimezone(option) {
      this.eventLocal.extendedProps.timezone = option;
    },
    checkAvailability() {
      const dates = [];
      const finalHours = [];

      const availabilityRulesArray = this.$store.getters.availabilityRules.unpaginated;

      const newDateTime = new Date().getTime();

      for (const row of availabilityRulesArray) {
        const startDateStr = new Date(row.startDate).toISOString();
        const endDateStr = new Date(row.endDate).toISOString();

        //  If the availability rule has a Start Date...
        if (row.startDate != null) {
          // If it is the first time here, and the start of the rule is higher than NOW
          if (this.startDate == null && row.startDate > newDateTime) {
            this.startDate = new Date(row.startDate);
            //this.endDate = new Date(row.endDate).toISOString();
            this.eventLocal.start = this.startDate.toISOString();
            this.eventLocal.end = new Date(
              this.startDate.setTime(this.startDate.getTime() + 1 * 60 * 60 * 1000),
            ).toISOString();
          }

          if (row.endDate != null) {
            dates.push(...this.getDatesInRange(row.startDate, row.endDate));
            for (const data of this.getDatesInRange(row.startDate, row.endDate)) {
              finalHours.push({
                date: new Date(data).toISOString().split('T')[0], 
                endDate: new Date(row.endDate).toISOString(), 
                startHour: new Date(row.startDate).getHours(), 
                minTime: row.minTimeMin, 
                maxTime: row.maxTimeMin,
              });
            }
          } else {
            const index = availabilityRulesArray.indexOf(row) + 1;
            dates.push(...this.getDatesInRange(row.startDate, availabilityRulesArray[index]?.startDate || '1990-01-01'));
          }
        }

        // If the availability rule has an End Date but NOT a Start Date...
        // AQUI NUNCA ENTRAREMOS; EL MODAL DE CREACION NO PERMITE FECHA NULA...
        if (row.endDate != null && row.startDate == null) {
          const index = availabilityRulesArray.indexOf(row) - 1;
          dates.push(...this.getDatesInRange(availabilityRulesArray[index]?.endDate || '1990-01-01', row.endDate));
        }
      }
      this.dates = dates;
      this.finalHours = finalHours;
    },
    getAvailabilityRulesMessage(row) {
      //{{ index + 1 }}) {{ row.availability + ' - Starting at ' + getDateAndTimeStr(row.startDate) + ' and ending at ' + getDateAndTimeStr(row.endDate) }}<br>
      var rule = '';
      rule += row.availability + '. Starting at ' + this.getDateAndTimeStr(row.startDate) + ' and ending at ' + this.getDateAndTimeStr(row.endDate);
      if(row.maxTimeMin){
        rule += ' [Max time: ' + row.maxTimeMin + ' min]';
      }
      if(row.mimTimeMin){
        rule += ' [Min time: ' + row.minTimeMin + ' min]';
      }
      return rule;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

// The button style is broken when ading 'to' property. This fixes it.
.anchor-fix {
  line-height: 1.8;
}

.info-message {
  font-size: x-small;
  margin-bottom: 15px;
}
</style>
