<template>
  <div>
    <v-select
      v-model="selectedPlaceId"
      :filterable="false"
      :options="options"
      :placeholder="placeholder"
      label="description"
      @search="fetchOptions"
      @input="handlePlaceSelected"
    />
    <div v-show="selectedPlaceId" ref="map" class="map mt-2" />
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import vSelect from 'vue-select';

export default {
  name: 'PlacesMapInput',
  components: { vSelect },
  model: {
    prop: 'location',
    event: 'update:location',
  },
  props: {
    location: {
      type: Object, // Location
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      google: null,
      map: null,
      autocomplete: null,
      placesService: null,

      marker: null,
      bounds: null,

      selectedPlaceId: null,
      options: [],
      defaultCenter: { //TODO: get from Map addon settings
        lat: 41.391519515630414, 
        lng: 2.1808292911030125 
      },
      defaultZoom: 13 //TODO: get from Map addon settings
    };
  },
  async mounted() {
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });
    this.google = await loader.load();

    console.log('PlacesMapInput mounted this.defaultCenter', this.defaultCenter);
    this.map = new this.google.maps.Map(
      this.$refs.map,
      {
        center: this.defaultCenter,
        zoom: this.defaultZoom,
        controlSize: 25,
        mapTypeId: 'roadmap',
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
      },
    );

    this.autocompleteService = new this.google.maps.places.AutocompleteService();
    this.placesService = new this.google.maps.places.PlacesService(this.map);

    // // Bias the autocomplete results towards current map's viewport.
    this.map.addListener('bounds_changed', () => {
      this.bounds = this.map.getBounds();
    });

    if (this.location) {
      this.handleLocationChange(this.location);
    } else {
      this.requestCenterOnCurrentLocation();
    }
  },
  methods: {
    requestCenterOnCurrentLocation() {
      if (navigator?.geolocation?.getCurrentPosition) {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => {
            console.log('requestCenterOnCurrentLocation coords', coords)
            this.map.setCenter({
              lat: coords.latitude ?? this.defaultCenter.lat,
              lng: coords.longitude ?? this.defaultCenter.lng,
            });
          },
        );
      }
    },
    async fetchOptions(search, loading) {
      if (!search) {
        this.options = [];
        return;
      }

      loading(true);
      const promise = new Promise((resolve) => {
        this.autocompleteService.getPlacePredictions(
          {
            input: search,
            bounds: this.bounds,
          },
          (predictions, status) => {
            if (status !== this.google.maps.places.PlacesServiceStatus.OK) {
              resolve([]);
              return;
            }

            resolve(predictions);
          },
        );
      });

      loading(false);
      const predictions = await promise;

      this.options = predictions
        .map(({ place_id: code, description }) => ({ code, description }));
    },
    handleLocationChange(location) {
      this.selectedPlaceId = {
        code: 'dummy-id', // use dummy id to avoid fetching the placeId from geocoding.
        description: location.name || location.address,
      };
      this.options = [this.selectedPlaceId];

      this.handlePlaceChanged({
        name: location.name || location.address,
        geometry: {
          location: {
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude),
          },
        },
      });
    },
    handlePlaceSelected(option) {
      if (!option) {
        this.$emit('update:location', null);
        this.handlePlaceChanged(null);

        return;
      }

      this.placesService.getDetails({ placeId: option.code }, (placeResult, status) => {
        if (status === this.google.maps.GeocoderStatus.OK) {
          const postalCode = placeResult.address_components
            .find(({ types }) => types.includes('postal_code'))?.long_name;
          const locality = placeResult.address_components.find(({ types }) => types.includes('locality'))?.long_name;
          const region = placeResult.address_components
            .find(({ types }) => types.includes('administrative_area_level_1')).long_name;
          const location = {
            name: option.description,
            latitude: placeResult.geometry.location.lat(),
            longitude: placeResult.geometry.location.lng(),
            postalCode,
            locality,
            region,
          };
          this.$emit('update:location', location);
          this.handlePlaceChanged(placeResult);
        }
      });
    },
    handlePlaceChanged(place) {
      // Clear out the old marker
      if (this.marker) {
        this.marker.setMap(null);
      }

      if (!place) {
        this.options = [];

        return;
      }

      if (!place.geometry || !place.geometry.location) {
        return;
      }

      this.marker = new this.google.maps.Marker({
        map: this.map,
        title: place.name,
        position: place.geometry.location,
      });

      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        const bounds = new this.google.maps.LatLngBounds();
        bounds.union(place.geometry.viewport);
        this.map.fitBounds(bounds);
      } else {
        let centerPosition = place.geometry.location;
        if(!centerPosition.lat || !centerPosition.lng || (!this.isNumber(centerPosition.lat) && !this.isNumber(centerPosition.lng))){
          centerPosition = this.defaultCenter;
        }
        console.log('handlePlaceChanged centerPosition', centerPosition);
        this.map.setCenter(centerPosition);
        this.map.setZoom(this.defaultZoom);
      }
    },
    isNumber(value) {
      return typeof value === 'number' && isFinite(value);
    }
  },
};
</script>

<style lang="scss" scoped>
  .map {
    height: 200px;
  }
</style>

<style>
  .pac-container {
    z-index: 1051 !important;
  }
</style>
